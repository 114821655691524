import sidebar from "components/Sidebar/sidebar-menu.json";
import UlTreeBuilder from "components/UlTreeBuilder";
import './style.css';

function Sidebar() {
    return (
        <div className="Menu">
            <UlTreeBuilder arrObjs={sidebar.menu}/>
        </div>
    );
}

export default Sidebar;