/*
*The LiCreator function simply returns a li element.
*/
const LiCreator = (text, link, liChildren) => {
    return (
        <li className="nav-item">
            <a className="nav-link p-1" href={link}>
                {text}
            </a>
            {liChildren}
        </li>
    )
};

/*
*The UlTreeBuilder component returns an array of nested unordered list.
*@param arrObjs: the menu array. In our case, we are going to pass menuTreeJSON.menu into it later.
*@param recLink: the link from parent element.
*/

const UlTreeBuilder = ({arrObjs, recLink}) => {
    console.log('arrObjs, recLink',arrObjs, recLink);

    let MenuItems = [];

    const UlParent = ({UlChildren}) => {// this component will simply create a ul element with children
        return (<ul>{UlChildren}</ul>)
    };

    for (let i = 0; i < arrObjs.length; i++) {
        if (arrObjs[i].children) { // if the item has children
            let ulWithChildren = <UlTreeBuilder arrObjs={arrObjs[i].children} recLink={arrObjs[i].link}/>; //we then create a ul with children, by self invoking UlTreeBuilder
            let liSingle = LiCreator(arrObjs[i].text, recLink != undefined ? recLink + arrObjs[i].link : '', ulWithChildren);// then we add this created ul into a new li
            MenuItems.push(<UlParent key={(Date.now()+(Math.random()*10))} UlChildren={liSingle}/>)//then we put this li into ul, and push ul into the MenuItems array of the current recursion level
        } else { // if the item has no children
            let liSingle = LiCreator(arrObjs[i].text, arrObjs[i].link);//we simply create a li with no children
            MenuItems.push(<UlParent key={(Date.now()+(Math.random()*10))+'key'} UlChildren={liSingle}/>)//then we put this li into ul, and push ul into the MenuItems array of the current recursion level
        }
    }

    return (MenuItems)
};


export default UlTreeBuilder;