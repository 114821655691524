import Content from "components/Content";
import Header from "components/Header";
import Footer from "components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

function App() {
    return (
        <div className="App">
            <Header/>
            <Content />
            <Footer />
        </div>
    );
}

export default App;
