import React, {useState, useEffect} from 'react';
import Markdown from 'markdown-to-jsx';
import Sidebar from "components/Sidebar";

function Content() {
    const file_name = 'introduction/introduction.md';
    const [post, setPost] = useState(file_name);
    const git_raw_url = 'https://raw.githubusercontent.com/stakater/StakaterCloudDocs/master/content/sre/';

    useEffect(() => {
        fetch(git_raw_url + file_name)
            .then(res => res.text())
            .then(res => setPost(res))
            .catch(err => console.log(err));
    });

    return (
        <div className="container">
            <div className="row">
                <div className="col-3">
                    <Sidebar />
                </div>
                <div className="col-9">
                    <Markdown>
                        {post}
                    </Markdown>
                </div>
            </div>
        </div>
    );


    // another way work around
    /*useEffect(() => {
        import(`../../markdown/${file_name}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });*/
}

export default Content;