import header from "components/Header/header-menu.json";
import UlTreeBuilder from "components/UlTreeBuilder";
import './style.css';

function Header() {
    return (
        <div className="Menu">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <UlTreeBuilder arrObjs={header.top}/>
                </div>
            </nav>
        </div>
    );
}

export default Header;